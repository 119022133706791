import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Skills" />

    <h1>Skills</h1>

    <p>I want to put a matrix of skills here. Someday.</p>
  </Layout>
)

export default SecondPage
